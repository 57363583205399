define("discourse/plugins/tai-locale/discourse/initializers/tai-initializer", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators"], function (_exports, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: 'tai_locale',
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.tai_locale_disable_others) return;
      (0, _pluginApi.withPluginApi)('0.11.1', api => {
        var _dec, _obj;
        api.modifyClass('controller:preferences/interface', (_dec = (0, _decorators.default)(), (_obj = {
          availableLocales() {
            return [{
              value: 'en',
              name: 'English'
            }, {
              value: 'tai_Han',
              name: '台語（漢字）'
            }, {
              value: 'tai_Rom',
              name: 'Tâi-gí (Lô-jī)'
            }];
          }
        }, (_applyDecoratedDescriptor(_obj, "availableLocales", [_dec], Object.getOwnPropertyDescriptor(_obj, "availableLocales"), _obj)), _obj)));
      });
    }
  };
});